import 'twin.macro';
import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/global/Seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="NOT FOUND" />
    <div tw="bg-gradient-to-r from-green via-purple-500 to-green-500 text-white min-h-screen flex items-center">
      <div tw="container mx-auto p-4 flex flex-wrap items-center">
        <div tw="w-full md:w-5/12 text-center p-4" />
        <div tw="w-full md:w-7/12 text-center md:text-left p-4">
          <div tw="text-6xl font-medium mb-5">404</div>
          <div tw="text-xl md:text-3xl font-medium mb-4">
            Oops. This page has gone missing.
          </div>
          <div tw="text-lg mb-8">
            You may have mistyped the address or the page may have moved.
          </div>
          <Link to="/" tw="border border-white rounded p-4">
            Go Home
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
